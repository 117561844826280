/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react"

export default function AGB() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 py-4 md:px-0 md:py-12">
        <div className="text-center">
          <h1 className="mt-1 text-4xl uppercase font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            AGB's
          </h1>
        </div>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
              }}
            >
              1. ANGEBOT / OFFERTE
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            1.1 Angebote sind, ohne andere Angabe in der Offerte, einen Monat
            gültig.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            1.2 Angebote basieren auf in der Ausschreibung genannten
            Rahmenbedingungen und den technischen Vorgaben im
            Leistungsverzeichnis. Werden nachträglich konstruktive Änderungen
            verlangt, erfolgt eine Preisanpassung.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            1.3 Baustellen müssen durch den Anbieter in der Offertphase nicht
            zwingend besichtigt werden.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            1.4 Bei speziellen Anforderungen und Erschwernissen, die im
            Leistungsverzeichnis nicht benannt waren, werden die Positions- /
            Einheitspreise angepasst.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            1.5 Angebote basieren auf handelsüblichen Halbfabrikaten.
            Spezialanfertigungen, welche in der Offerte nicht spezifiziert sind,
            können Positionspreise und Lieferfristen verändern.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            1.6 Bei Aufteilung in Lose behält sich der Anbieter vor, die
            Positions- / Einheitspreise anzupassen.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            1.7 Etappenlieferungen müssen dem Anbieter gemeldet werden. Er
            behält sich vor, zusätzliche Aufwendungen in Regie zu verrechnen.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            1.8 Pauschalangebote gelten für die im Leistungsverzeichnis
            beschriebenen Mengen und Ausführungen. Änderungen führen zu
            Preiskorrekturen (SIA 118).
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            1.9 Unser Angebot darf nicht als Preisanfrage für eine Gegenofferte
            verwendet werden und / oder für sonstige Zwecke missbraucht werden.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
              }}
            >
              2. POSITIONS- / EINHEITSPREISE / MENGENANGABEN
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            2.1 Beim Offertvergleich ist der Auftragsgeber verpflichtet,
            wesentlich zu tiefe Einheitspreise, die auf einen wahrscheinlichen
            Übertragungs- und / oder Kalkulationsfehler hinweisen, dem Anbieter
            mitzuteilen und diesem ein Recht auf Korrektur zu gewähren.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            2.2 Angegebene Stückzahlen verstehen sich als mit gleicher Dimension
            und gleicher Spezifikation. Änderungen führen zu Preiskorrekturen.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            2.3 Einheitspreise gelten für die Herstellung eines Produktes gemäss
            Leistungsbeschrieb. Arbeiten an fremden Bauteilen sind nicht
            inbegriffen.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            2.4 Weicht die effektiv hergestellte und montierte Menge von der
            offerierten Menge ab, werden Minder- bzw. Mehrpreise verrechnet.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            2.5 Preise bleiben fest, wenn der Bau für Bauvollendungen innerhalb
            eines Jahres seit der Auftragserteilung beendet ist. Danach behält
            sich der Anbieter vor, Teuerungszuschläge nach Baukostenindex gelten
            zu machen.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            2.6 Ändert der Stahl- / Metallpreis von mehr als ± 5% gemäss LME
            (London Metall Exchange) zwischen dem Zeitpunkt des Angebots und der
            vertragsmässigen Erfüllung des Auftrags, wird der Preis angepasst.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
              }}
            >
              3. LIEFERFRISTEN / AUFTRAGSERTEILUNG / BESTELLÄNDERUNGEN
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            3.1 Lieferfristen gelten ab Auftragserteilung und nach Einigung über
            die Ausführung.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            3.2 Zu genehmigende Fabrikationspläne müssen innerhalb von 3
            Arbeitstagen kontrolliert und visiert retourniert werden. Endtermine
            könnten sonst nicht mehr garantiert werden.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            3.3 Mündliche Bestellungen und nachofferierte Arbeiten werden erst
            nach schriftlicher Auftragserteilung durch den Auftraggeber
            ausgeführt.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
              }}
            >
              4. KONVENTIONALSTRAFE / ERFÜLLUNGSGARANTIE / GARANTIE
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            4.1 Konventionalstrafen werden nur akzeptiert, wenn der Anbieter bei
            der Terminplanung volles Mitspracherecht hatte. Ist die Baustelle
            zum geplanten Montagebeginn nicht bereit, oder liegen nicht
            verschuldete Terminverzögerungen vor, entfällt ein Anspruch auf
            Konventionalstrafe.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            4.2 Die Garantiefrist beträgt 2 + 5 Jahre nach SIA 118 und beginnt
            mit dem Datum der Arbeitsvollendung.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            4.3 Die Garantiefrist für Antriebsmotoren, elektrische,
            pneumatische, mechanische und hydraulische Geräte, ferner für
            Steuerungen und bewegliche Gebrauchsteile beträgt 1 Jahr.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            4.4 Für Konstruktionen, die schriftlich abgemahnt, aber aufgezwungen
            wurden, besteht kein Haftungs- oder Garantieanspruch.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            4.5 Werden Konstruktionen verlangt, die den Normen oder
            Sicherheitsanforderungen für Personen nicht genügen, behält sich der
            Anbieter das Recht vor, ohne Kostenfolge von der
            Werkvertragsposition zurückzutreten.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
              }}
            >
              5. PLANUNG / TERMINPLANUNG
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            5.1 Die Planung des Anbieters umfasst die Herstellung der für die
            Ausführung der Werkstücke notwendigen Pläne, Skizzen und Unterlagen.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            5.2 Die Fabrikationspläne werden im Doppel zur Genehmigung
            eingereicht und geringfügige Änderungen nur einmal kostenlos
            geändert.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            5.3 Die Fabrikationspläne bleiben geistiges Eigentum des Anbieters.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            5.4 Nach Auftragserteilung wird gemeinsam mit dem Auftraggeber der
            Terminplan erstellt und die Reihenfolge der Etappenlieferung
            fixiert.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
              }}
            >
              6. HERSTELLUNG / MONTAGE
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.1 Der Anbieter erstellt das Werk nach gültigen, branchenüblichen
            Normen und Richtlinien.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.2 Behördliche Auflagen, statische und bauphysikalische
            Anforderungen müssen durch den Auftraggeber bekannt, bzw. vorgegeben
            werden.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.3 Wird nach theoretischen Massen hergestellt, ist der Auftraggeber
            für die Einhaltung der vorgegebenen Masse am Bau voll
            verantwortlich.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.4 Extreme Witterungsverhältnisse oder höhere Gewalt berechtigen
            den Anbieter Montagearbeiten zu unterbrechen. Endtermine könnten
            dann nicht mehr garantiert werden.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.5 Mehraufwendungen für nicht vom Anbieter verschuldete
            Montageunterbrüche sowie fehlerhaftes Aufbieten auf die Baustelle
            werden in Regie verrechnet.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.6 Der Unternehmer behält sich das Recht vor, Montagen durch
            qualifizierte Drittfirmen ausführen zu lassen.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.7 Montagerisiken werden vom Anbieter nur übernommen, wenn diese
            schriftlich mitgeteilt wurden. Bodenheizung, Leitungen etc. sind auf
            den Ausführungsplänen des Unternehmers durch den Auftraggeber
            einzuzeichnen und am Montageort zu bezeichnen. Werden diese Hinweise
            unterlassen, übernimmt der Unternehmer für Schäden keine Haftung.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.8 Für die Montage werden durch den Auftraggeber kostenlos zur
            Verfügung gestellt:
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.8.1 Stromanschlüsse auf jedem Stockwerk
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.8.2 Schuttmulden
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.8.3 Arbeitsgerüste für Arbeiten, welche ein 3m hohes Rollgerüst
            übersteigen
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.8.4 Schutzgeländer, Netze, etc. nach behördlichen Vorschriften.
            Der Auftraggeber ist verantwortlich für:
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.8.5 Tragfähiger Zugang zum Montageort
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.8.6 Schutz der Umgebung und angrenzenden Bauteilen
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.8.7 Abstellplatz für Material und Montagematerial während der
            Bauzeit
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.8.8 Dauerhafte Kennzeichnung von Achsen und Meterrissen auf jedem
            Stockwerk auf der Baustelle vor der Maßaufnahme des Anbieters
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.9 Die folgenden Arbeiten sind Sache des Auftraggebers, wenn im LV
            nicht erwähnt:
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.9.1 Erstellen von Aussparungen, Kernlochbohrungen und
            Spitzarbeiten sowie Zugiessen derselben nach Montage des Werkstücks
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.9.2 Abdicht- und Isolierarbeiten zwischen Werkstück und fremden
            Bauteilen, insbesondere Maueranschlüssen
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.9.3 Schutz von Werkstücken mit Folien, Verschalungen, etc.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.9.4 Schlussreinigung von Werkstücken mit Ausnahme der ersten
            Reinigung grober Verschmutzung bei Montage.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.9.5 Notwendige Trenn- und/oder Schutzwände und dgl. sind bauseits
            und für uns kostenlos zu erstellen.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.10 Handmuster werden leihweise vom Anbieter gratis zur Verfügung
            gestellt. Herzustellende Muster, Materialprüfungen etc. werden nach
            Vereinbarung gegen Verrechnung erstellt.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.11 Minimale Schäden bis 0.5% der lackierten Oberfläche, welche bei
            der Montage entstanden sind, werden vor Ort ausgebessert und
            berechtigen nicht eine neue Werkslackierung zu verlangen.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.12 Die Kosten für in Auftrag gegebene Expertisen an montierten
            Metallbauarbeiten hat der Auftraggeber zu tragen.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
              }}
            >
              7. REGIEARBEITEN
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            7.1 Regiearbeiten werden nach den aktuellen Regieansätzen des
            jeweiligen Regionalverbandes der Schweizerischen Metall- Union
            verrechnet.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            7.2 Regiearbeiten sind von den Rabatt-, Skonto- und
            Pauschalpreisvereinbarungen auf Akkordarbeiten ausgenommen.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            7.3 Regiearbeiten, die von der örtlichen Bauleitung angeordnet
            werden, sind für den Auftraggeber verbindlich.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            7.4 Regiearbeiten werden generell nur mit Personal ausgeführt,
            welches für die Komplexität der auszuführenden Arbeit genügend
            qualifiziert ist.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
              }}
            >
              8. ABNAHME / TEILNAHME
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            8.1 Bewilligungen und behördliche Abnahmen sind Sache des
            Auftraggebers. Bei Nichtabnahme des Werks durch die zuständigen
            Behörden haftet der Anbieter nicht.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            8.2 Nach der Fertigstellung ist die Arbeit durch den Auftraggeber
            umgehend zu prüfen. Werden 10 Tage nach der Fertigstellung keine
            sichtbaren Mängel gemeldet, gilt das Werk als einwandfrei und
            abgenommen.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            8.3 Die Montage von Glas, Dichtungen, exponierten Beschlägen,
            Zubehör etc. wird durch die Bauleitung zur Montage abgerufen und
            sofort nach Montage abgenommen. Das Bruch-, Diebstahl- und
            Beschädigungsrisiko geht nach Abnahme auf den Auftraggeber über.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            8.4 Etappenlieferungen werden etappenweise abgenommen.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
              }}
            >
              9. ABZÜGE / ZUSCHLÄGE / ZAHLUNGSBEDINGUNGEN
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            9.1 Honorare Dritter dürfen dem Anbieter nur in Rechnung gestellt
            werden, wenn diese in der Ausschreibung und im Leistungsverzeichnis
            quantifiziert worden sind.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            9.2 Bei Pauschalaufträgen können keine Abzüge wie Baustrom,
            Bauwasser, Reinigung etc., zusätzlich in Abzug gebracht werden.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            9.3 Abzüge können nicht geltend gemacht werden für:
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            9.3.1 Weitere Versicherungen als die übliche Betriebshaftpflicht
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            9.3.2 Administrative Aufwände, EDV, Telefonkosten und Spesen des
            Auftragsgebers.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            9.4 Zuschläge werden für die folgenden Aufwendungen verrechnet:
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            9.4.1 Steuern, Abgaben, Zölle, Gebühren, Bewilligungen und
            Aufwendungen im Zusammenhang mit Auslandlieferungen und Montagen.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            9.5 In Auftrag gegebene Nacht-, Samstag- und Sonntagarbeit werden
            gemäss den Regietarifen des jeweiligen Regionalverbandes der
            Schweizerischen Metall- Union verrechnet.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            9.6 Die Zahlungsfrist beträgt 30 Tage nach Rechnungsstellung. Nach
            Ablauf dieser Frist kann kein Skonto geltend gemacht werden und es
            wird Verzugszins verrechnet. Ungerechtfertigte Abzüge werden
            nachgefordert.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            9.7 Bei Aufträgen mit Volumen von mehr als Fr. 50’000.- werden
            mindestens 35 % vom Verkaufspreis bei Bestellung in Rechnung
            gestellt.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            9.8 Werden Forderungen bestritten und / oder ist der Besteller in
            Zahlungsverzug, kann der Unternehmer prophylaktisch bis zur Einigung
            das Bauhandwerkerpfandrecht eintragen lassen.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Diese Offert- und Lieferbedingungen sind integrierender Bestandteil
            des Angebotes und des Werkvertrages und gelten vom Auftraggeber ohne
            ausdrücklich anders lautende Vereinbarungen akzeptiert.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "11.25pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Aktuell gültige Version vom 1. Januar 2019
          </span>
        </p>
      </div>
    </div>
  )
}
