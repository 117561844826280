import * as React from "react"
import AGB from "../components/agb"
import HeroHome from "../components/hero-home"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AGBsPage = () => (
    <Layout>
        <Seo title="Home" />
        <AGB />
    </Layout>
)

export default AGBsPage
